$('#account_payment').on('change', 'input[name=payment_type]', function() {
	$('#account_payment [name=summa]').data('required', this.value == '2' );
});
// if($('[name=summa]').length){
// 	$('[name=summa]').val($('[name=order_id] option:selected').data('sum'));
// }
$('#account_payment [name=order_id]').change(function(){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected', form);
	var sum = selected_option.data('sum');
	var sumDisc = selected_option.data('sum_disc');
	var orders_id = $(this).val();
	$('.order_slider').removeClass('active');
	$('div[name='+orders_id+']', '.reviews-ordered').addClass('active');
	$('.prepay-disc-desc', form)[sum == sumDisc ? 'hide' : 'show' ]();
	$('[name=summa]', form).val('');
	$('#payment-ot-total', form).text(selected_option.data('ot_total_str'));
	$('#payment-prepay', form).text(selected_option.data('prepay'));
	$('#payment-to-prepay', form).text(selected_option.data('to_prepay'));

	if( selected_option.data('payMethod').length ) {
		$('[name=payment][value=' + selected_option.data('payMethod') + ']').prop('checked', true).trigger('change');
	}

	var paymentType = $('[name=payment]:checked').data('type');
	$('#labelOnLinePay').text('');
	$('.full-sum', form).html('<strong style="font-size:14pt">'+selected_option.data('to_prepay')+'</strong>');
	if( paymentType!='OffLine' ) {
		if( selected_option.data('disc_value') > 0 && selected_option.data('val_ship') == 1 ) {
			$('#labelOnLinePay').text('(������ ' + selected_option.data('disc_value') + '% ��� ������ ������ � ���������� ��������):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% � ���������� ��������');
		} else if( selected_option.data('disc_value') > 0 ) {
			$('#labelOnLinePay').text('(������ ' + selected_option.data('disc_value') + '%  ��� ������ ������):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% ');
		} else if( selected_option.data('val_ship') == 1 ) {
			$('#labelOnLinePay').text('(���������� ��������):');
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ ���������� ��������');
		}
	}

}).trigger('change');
$('[name=payment]').change(function(){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected');
	var sumDisc = selected_option.data('sum_disc');
	var paymentType = $(this).data('type');
	$('.full-sum', form).html('<strong style="font-size:14pt">'+selected_option.data('to_prepay')+'</strong>');
	if( paymentType!='OffLine' ) {
		if( selected_option.data('disc_value') > 0 && selected_option.data('val_ship') == 1 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% � ���������� ��������');
		} else if( selected_option.data('disc_value') > 0 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% ');
		} else if( selected_option.data('val_ship') == 1 ) {
			$('.full-sum', form).html('<strong style="font-size:14pt">'+sumDisc +' P</strong><br> � ������ ���������� ��������');
		}
	}
});
$(".account_payment_button").click(function(e){
	var rad = $('input[name=payment_type]', this);
	rad.prop('checked', true).trigger('change');
	$(".account_payment_button").find('jq-radio').removeClass('checked');
	$(".account_payment_button").removeClass('active');
	$(this).addClass('active');
	$(this).find('jq-radio').addClass('checked');
});
$("#for_example_five_persent").click(function(e){
	var form = $('#account_payment');
	var selected_option = $('[name=order_id] option:selected', form);
	var sum = selected_option.data('sum');
	$('[name=summa]', form).val(Math.ceil(sum * 0.05,10));
});
