var customer = window.customer || new Customer();
init_sc_autocomplete();
initSCSuggestion();
initCreateAccountForm();

var ajaxSCFormOptions = {
	dataType: 'json',
	error: function(context, xhr, e, status) {
	},
	uploadProgress: function(event, position, total, percent) {
	},
	beforeSubmit: function(formData, jqForm, options) {
		if( parse_str(options.url).action.indexOf('getCustomerCity') >= 0 ) {
			$('#form-get-login').hide();
		}
		// �������� ��������, ���� �����
		if( parse_str(options.url).action.indexOf('getShipping') >= 0 ) {
			if( formData.length ) {
				if ( window.ga ) {
					ga('ec:setAction','checkout', {
						'step': 3,
						'option': formData[0].value
					});
					ga('send', 'pageview');
				}
				if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
				{
					window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_city');
				}
			}
			$('#accountInfo #acc-change-btn').hide();
			$('#customerAddress, #delivery').empty();
			$('#delivery').addClass('loading');
		}

		if( parse_str(options.url).action.indexOf('getAddressFields')>=0 ) {
			$('#customerAddress').empty();
			$('#customerAddress').addClass('loading');
		}
		if( $('#'+jqForm.attr('id')+'-loading').length ) {
			$('#'+jqForm.attr('id')+'-loading').css('visibility', 'visible');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'visible');
		}
		if( $(jqForm).attr('id') == 'form-get-login' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).next().attr('id') == 'form-get-content' ) {
				$(jqForm).hide().next().show();
			}
			$.each( $('#form-get-delivery').serializeArray(), function(i, val) {formData.push(val); } );
		}
		if( $(jqForm).attr('id') == 'form-get-content' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).prev().attr('id') == 'form-get-login' ) {
				$(jqForm).hide().prev().show();
			}
		}
		formData.push({name: 'dataType', value: options.dataType});
	},
	success: function(responseText, statusText, xhr, $form) {				
		// �������� ��� ����
		if ( responseText.products != undefined && responseText.totals != undefined ) {
			$('.j-cart-content-real-total').text( responseText.totals.real_total_text );
			$('.j-cart-discount').text( responseText.totals.total_discount_text );
			$('.j-cart-full-total').text( responseText.totals.total_text );
		}
		if ( responseText.scrollTo == '#customerAddress' && responseText.resetCity ) {
			$('.skip').attr('action', $('.skip').attr('action') + '.getShipping.getAllContent' ).submit();
			return;
		}
		if( $form.attr('id') == 'createAccountForm' ) {
			$('[name=email_address]', $form).prop('readonly', true);
			$('[name=password]', $form).closest('.form-row').hide();
			if ( window.ga ) {
				ga('ec:setAction','checkout', {
					'step': 2,
					'option': ''
				});
				ga('send', 'pageview');
			}
			if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
			{
				window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_nameemailtel_filled');
			}
		}
		
		if( $('#'+$form.attr('id')+'-loading').length ) {
			$('#'+$form.attr('id')+'-loading').css('visibility', 'hidden');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'hidden');
		}
		$.each( responseText.data, function( id, data ) {
			var place = $('#' + id);
			if( place.length ) {
				place.html( data );
				place.removeClass('loading');
				// hidePaymentButtons(); // TODO
				if( place.find('.default-validate-form').length ) {
					place.find('.default-validate-form').validate();
				}
				if( place.find('.ajaxSCForm').length ) {
					place.find('.ajaxSCForm').ajaxForm(ajaxSCFormOptions);
				}
				place.find('.tooltip').tooltip_init();
				// if( place.find(':checkbox').length ) {
				// 	place.find(':checkbox').checkbox();
				// } // TODO
				place.find('.phone-11-dig').phoneMask();
				if( $.inArray('setInputFocus', responseText.options)!=-1 ) {
					place.find('input:text:first').focus();
				}
				if( $('select.delivery-fitting', place).length ) {
					$('select.delivery-fitting', place).trigger('change.fitting');
				}
				$('.with-placeholder input', place).each(function() {
					$(this)[ $(this).val().trim().length == 0 ? 'removeClass' : 'addClass' ]('edited');
				});
				if( $('.fitting-descrption-popup-opened').length ) {
					$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
						e.preventDefault();
						$.magnificPopup.open({
							items: {
								src: '#sc-primerka-popup',
								type: 'inline',
							},
						});
					});
					$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
						e.preventDefault();
						$.magnificPopup.close();
					});
					$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
						e.preventDefault();
						$.magnificPopup.close();
						$('html, body').animate({ scrollTop: $('.cart-products').offset().top });
					});
				}
			}
			// BOF _gaq.push
			// EOF _gaq.push
		} );
		if( responseText.customerInfo != undefined ) {
			customer.logined = true;
			customer.info.email = responseText.customerInfo.email;
			customer.info.customer_id = parseInt(responseText.customerInfo.customers_id);
		}
		if( $.inArray('cityFocus', responseText.options)!=-1 ) {
			$('#customerCity .city-tab.active input[name=city]').focus();
		}
		if( $.inArray('delivery_date_for_time_change', responseText.options)!=-1 ) {
			$('select.delivery_date_for_time').delivery_date_for_time_change();
		}
		if( responseText.scrollTo && responseText.scrollTo != '#delivery' && responseText.scrollTo != '#customerCity' && responseText.scrollTo != '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $(responseText.scrollTo).offset().top });
		} else if ( responseText.scrollTo && responseText.scrollTo == '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $('#delivery').offset().top });
		}
		if ( responseText.shipping_cost != undefined ) {
			$('.j-cart-delivery-total').text( responseText.shipping_cost + ' ' + window.PROSKTR.currencies.rightMin );
			$('.j-cart-full-total').text( responseText.total_formatted + ' ' + window.PROSKTR.currencies.rightMin );
		}
		var stepsList = $('.order-steps');
		init_sc_autocomplete();
		initSCSuggestion();
		toggleDontCall();
	}
}
$('.ajaxSCForm').each(function() {
	$(this).ajaxForm(ajaxSCFormOptions);
});
$('#customerAddress, form#createAccountForm').on('change', 'input,select,textarea', function(e) {
	var dat = { 'dataType': 'json', 'forSave': {}};
	dat.form = $(this).closest('form').attr('id');
	dat['forSave'][$(this).attr('name')] = this.value;
	$.ajax({
		url: 'shopping_cart.php?action=setCustomerInfo&ajax=3',
		data: dat,
		dataType: 'json',
		type: 'POST',
		success: function(msg) {}
	});
});
$('#accountInfo').on('click', '.pass-forgotten', function(e) {
	el = this;
	form = $(el).closest('form');
	var email = form.find('input[name=email_address]').val() ? form.find('input[name=email_address]').val() : form.find('input[name=phone]').val();
	customer.sendNewPass({
			email_address: email
		},
		function( msg ) {
			$(el).closest('.pass-row').find('.hint').html(msg);
		}
	);
	e.preventDefault();
});
customer.setCallback('onLogin', function() {
	$('#form-get-customer-city').submit();
});
$(document).on('click', '.j-s-sbmt', function(e) {
	e.preventDefault();
	$(this).closest('form').submit();
	if ( $(this).hasClass('toOrder') && !$(this).closest('form').hasClass('form-valid-error') ) {
		$('#accountInfo').hide();
		$('#order-tab').show();
		$('#delivery').empty();
		$('#customerAddress').empty();
	}
});
$.fn.delivery_date_for_time_change = function() {
	this.each(function() {
		var delivery_row = $(this).closest('.delivery_selects');
		var time_select = delivery_row.find('[name='+$(this).attr('time')+']');
		var date_select = delivery_row.find('.delivery_date_for_time');
		var fitting_select = delivery_row.find('.delivery-fitting');
		time_select.empty();
		var arr = window[$(this).attr('time')];
		for (var i = 0; i < arr.length; i++) {
			if( arr[i][0]==$(this).val() ) {
				var o = new Option( arr[i][1], arr[i][1])
				$(o).html( arr[i][1] );
				time_select.append(o);
			}
		}
		date_select.redrawMySelect();
		time_select.redrawMySelect();
		fitting_select.redrawMySelect();
	});
}
$('#delivery').on('change', 'select.delivery_date_for_time', function( e ) {
	$(this).delivery_date_for_time_change();
});
$('select.delivery_date_for_time').delivery_date_for_time_change();
$('#delivery').on('change', 'input[name=shipping]', function( e ) {
	var cureer_block = $(this).closest('.cureer-block');
	$('.delivery_selects').hide();
	$('.delivery_selects.select_' + $(this).val() ).show();
	cureer_block.addClass('active').siblings().removeClass('active').addClass('mfp-hide');
	$('.change-delivery').removeClass('mfp-hide');
	if( this.value=='pvz_pvz' ) {
		if( cureer_block.find('input[name=pvz_id]').val().length==0 ) {
			$('#delivery .shops_map_call').trigger('click');
			this.checked = false;
			return false;
		}
	}
	$('.change-delivery').removeClass('mfp-hide');
	$('.more-delivery').addClass('mfp-hide');
	$(this).closest('form').attr('action', 'shopping_cart.php?ajax=1&action=setShipping.getAddressFields.getPayments') // ������ �������, ������ ����� �� ��������:(
	$(this).closest('form').submit();
	$(this).closest('div.row').siblings(':visible').fadeOut({duration: 200});
	if ( window.ga ) {
		ga('ec:setAction','checkout', {
			'step': 4,
			'option': e.target.defaultValue
		});
		ga('send', 'pageview');
	}
	if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
	{
		window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_delivery');
	}
});
$('#delivery').change(function(e) {
	if( e.target.name=='shipping' || $(this).find('input[name=shipping]:checked').length==0 ) {
	} else {
		$(this).attr('action', 'shopping_cart.php?ajax=1&action=setShipping') // ������ �������, ������ ����� �� ��������:(
		$(this).closest('form').submit();
	}
});
$('#delivery').on( 'click', '.shops_map_call', function(e) {
	e.preventDefault();
	var options = $(this).data('map-options');
	$('#see-map').trigger('click');
	var el = $(this);
	ymaps.ready(function() {
		$('#map-popup').shopsMap($.extend(options, {
			pvzChoose: function( properties ) {
				$('#pvz_id').val( properties.point_ident );
				$('#pvz_resource').val( properties.delivery );
				$('#pvz__city').val( properties.point_city );
				$('#pvz_iml_date').val( properties.pvz_iml_date );
				// el.text('�������� ����� ������ �� �����');
				var cureer_block = el.closest('.cureer-block');
				$('.send-value', cureer_block).text( properties.dates.send_format ); // ���� ��������
				$('.delivery-value', cureer_block).text( properties.dates.delivery_from_format ); // ���� ��������
				$('.col-2 .head', cureer_block).text( properties.dates.delivery_days_forprint ); // ��� ��������

				$('.col-3 .head', cureer_block).text( properties.cost == 0 ? '���������' : properties.cost + '�' ).data('value', properties.cost); // ����
				$('.col-3 .head-on-line', cureer_block).remove();
				if( properties.free_cost == 1 && properties.cost > 0 ) {
					$('.col-3', cureer_block).append( '<strong class="head-on-line tooltip" title="<b>���� ������ �������� ����� ���� ���������� ��� ������ ������!</b><br />��������� �������� ���������� ����� ������ ������.">��������� <div class="icon"><div class="point-pay_cache_no"></div></div></strong>' ); // ����
					$('.col-3 .head-on-line', cureer_block).tooltip_init();
				}

				if( $.inArray(properties.delivery, options.typesToFitting) != -1 ) {
					$('.fitting-holder', cureer_block).show();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				} else {
					$('.fitting-holder', cureer_block).hide();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				}

				$('.error', cureer_block).text('');
				$('input[name=shipping]', cureer_block).prop('checked', true);
				$('select.delivery-fitting', cureer_block).val(properties.fitting).trigger('change');
				$('input[name=shipping]', cureer_block).trigger('change');
				$('#pvz_text', cureer_block).html('<b>����� ����� ��������� �� ������:</b><br>' + properties.point_city + ', ' + properties.address + ' (���� ' + properties.delivery_name + ')');
				$.magnificPopup.close();
			}
		}));
	});
} );

$(document).on('change', '.payment-type', function(){
	var block = $(this).closest('.payment-block');
	var li = $(this).closest('li');
	block.siblings('.payment-block').find('input.payment-type').prop('checked', false);
	block.find('input[name=paymentGroup]').prop('checked', true).trigger('change');
	$('#payments').find('.select-pay-list li').not(li).addClass('mfp-hide');
	$('.change-payment').removeClass('mfp-hide');
	$('.more-payments').addClass('mfp-hide');
	toggleDontCall();
	if ( $(this).closest('.other').length ) {
		$('.more-payments').addClass('no-hide');
	} else {
		$('.more-payments').removeClass('no-hide');
	}
});

$(document).on('click', '.change-delivery', function(e){
	e.preventDefault();
	$('#delivery').find('.delivery-radio-list .cureer-block').removeClass('mfp-hide');
	$('.change-delivery').addClass('mfp-hide');
});

$(document).on('click', '.change-payment', function(e){
	e.preventDefault();
	$('#payments').find('.select-pay-list li').removeClass('mfp-hide');
	$('.more-payments').removeClass('mfp-hide');
	$('.change-payment').addClass('mfp-hide');
});

$(document).on('click', '.more-payments', function(e){
	e.preventDefault();
	if( $(this).hasClass('no-hide') ) {
		return;
	}
	if( $(this).hasClass('disabled') ) {
		$('#payments .other').slideUp();
		$(this).removeClass('disabled');
		$(this).text( $(this).data('text-hide-more') );
	} else {
		$('#payments .other').slideDown();
		$(this).addClass('disabled');		
		$(this).text( $(this).data('text-show-more') );
	}
});

$('#delivery').on('click', '.del-all-product-label', function( e ) {
	e.preventDefault();
	data = {};
	var sendData = { products_id: [], cart_delete: [] };
	$('.del-product-label').each(function(){
		sendData.products_id.push( $(this).attr('products_id') );
		sendData.cart_delete.push( $(this).attr('products_id') );
	});
	var deleteProductCallback = typeof shoppingCart.options.deleteProductCallback == 'function' ? shoppingCart.options.deleteProductCallback( sendData ) : shoppingCart.deleteProductCallback( sendData ) ;
	$.ajax({
		url: '/ajax_shopping_cart.php?action=update_product&ajax=1',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		success: deleteProductCallback
	});
});

function toggleDontCall() {
	var checkedPaymentEl = $('#payments .payment-block .select-pay-list input:radio:checked');
	var checkedPayment = $(checkedPaymentEl).val();
	var currentVal = $('.dontCallLabel').data('current');
	if ( checkedPaymentEl.length && checkedPayment != 'cod' ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else if ( checkedPaymentEl.length && checkedPayment == 'cod' ) {
		$('.dontCallLabel').css('display', 'none');
		$('input[name=dontCall][value=off]').prop('checked', true);
	} else if ( $('input[name=dontCall]').data('showdontcall') == 1 ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else {
		$('.dontCallLabel').css('display', 'none');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	}
}
toggleDontCall();
$(document).on('change', 'input[name=dontCall]', function(){
	$('.dontCallLabel').data('current', $('input[name=dontCall]:checked').val());
});
$('.cart-products__item .del').click(function( e ) {
	e.preventDefault();
	data = {};
	data.products_id = $(this).closest('form').find('[name="products_id[]"]').val();
	data.fromPage = window.PROSKTR.content;
	shoppingCart.deleteProduct( data );
});
initNumberInput();
function initNumberInput(){
	$(document).on( 'click', '.add-product-minus', function(e){
		e.preventDefault();
		if ( $(this).hasClass('disabled') ) return false;
		var currentCount = $(this).closest('form').find('.cartCountValue').data('current-count');
		if ( currentCount > 1 )
		{		
			$(this).closest('form').find("input.currCount").val( currentCount - 1 );
			changeQuantity( this );
			$(this).closest('form').find('.add-product-plus').removeClass('disabled');
		}
		if ( currentCount == 2 )
		{
			$(this).addClass('disabled');
		}
	});

	$(document).on( 'click', '.add-product-plus', function(e){
		e.preventDefault();
		if ( $(this).hasClass('disabled') ) return false;
		var currentCount = $(this).closest('form').find('.cartCountValue').data('current-count');
		if ( currentCount < $(this).data('max-count') )
		{		
			$(this).closest('form').find("input.currCount").val( parseInt( currentCount ) + 1 );
			changeQuantity( this );
			$(this).closest('form').find('.add-product-minus').removeClass('disabled');
		}
		if ( currentCount == $(this).data('max-count') - 1 )
		{
			$(this).addClass('disabled');
		}
	});
}

function changeQuantity( el ) {
	var form =  $(el).closest('form');
	var data = form.serializeArray();
	data.push({name: 'fromPage', value: window.PROSKTR.content});

	$.ajax({
		url: form.attr('action'),
		type: 'POST',
		dataType: 'json',
		context: el,
		data: data,
		success: function(answer)  { // ����� ��������� ������
			shoppingCart.setQuantityNPrice( answer );
		},
	});
}

function initSCSuggestion() {
	if( window.PROSKTR.country.code != 'RU' ) {
		return ;
	}
	var 
		token = "483fd2413718ec3fb98454a0256d84481dd60fa5 ",
		type  = "ADDRESS",
		$street = $("#street-dadata");
		$house = $("#house-dadata");
		if ( !$street.length || !$house.length ) {
			return false;
		}
	if( $('#city-dadata').length == 0 ) {
		return;
	}

	$street.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "street",
		constraints: {
			locations: {
				city: $('#city-dadata').val(),
			},
		},
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

	$house.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "house",
		constraints: $street,
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

	setTimeout( function(){
		if ( $("#house-dadata").length && $("#street-dadata").val() != '' ) {
			$("#house-dadata").suggestions().fixData();
		}
	}, 1000);
};

function onSelectSuggestion(suggestion) {
	if ( suggestion.data.postal_code != null && $('#customerAddress input[name=index]').length )
	{
		$('#customerAddress input[name=index]').val( suggestion.data.postal_code ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=index]').val('');
	}
	if ( suggestion.data.house != null && $('#customerAddress input[name=house]').length )
	{
		$('#customerAddress input[name=house]').val( suggestion.data.house ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=house]').val('');
	}
	if ( suggestion.data.block != null && $('#customerAddress input[name=housing]').length ) {
		$('#customerAddress input[name=housing]').val( suggestion.data.block ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=housing]').val('').trigger('change');
	}
}

if( $('.fitting-descrption-popup-opened').length ) {
	$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
		e.preventDefault();
		$.magnificPopup.open({
			items: {
				src: '#sc-primerka-popup',
				type: 'inline',
			},
		});
	});
	$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
		e.preventDefault();
		$.magnificPopup.close();
	});
	$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
		e.preventDefault();
		$.magnificPopup.close();
		$('html, body').animate({ scrollTop: $('.cart-products').offset().top });
	});
}
$(window).scroll(function(){
	if ( $('#staticScrollTo:visible').length ) {
		var currentScroll = $(window).scrollTop();
		var btnStaticEl = $('#staticScrollTo');
		var btnStaticPos = btnStaticEl.offset();
		if ( currentScroll - btnStaticPos.top + $(window).height() - 70 < 0 ) {
			$('#fixedToOrder').show();
		} else {
			$('#fixedToOrder').hide();
		}
	}
});
$(window).trigger('scroll');
$(document).on('click', '.scroll-to-order', function(){
	var btnStaticEl = $('#staticScrollTo');
	$('html, body').animate({ scrollTop: btnStaticEl.offset().top });
	if ( $('#createAccountForm input[name=firstname]').length ) {
		$('#createAccountForm input[name=firstname]').focus();
	}
});

$(document).on('submit', '#customerAddressForm', function(e) {
	if ( $(this).hasClass('form-valid-error') ) {
		$('html, body').animate({ scrollTop: $(this).offset().top });
	}
});
$(document).on('change', '.customers-presents select[name=customers_bonus]', function() {
	var div = $(this).closest('.customers-presents');
	// div.find('.ajax-success').hide();
	div.find('.ajax-loader').show();
	$.ajax({
		type: 'POST',
		data: {'choosen_bonus': $(this).val()},
		url: 'ajax_shopping_cart.php?action=set_bonus',
		dataType: 'json',
		context: this,
		success: function(msg) {
			$('.j-recalc-bonus').submit();
		}
	});
});
$(document).on('submit', '.j-recalc-bonus', function(e){
	e.preventDefault();
	let data = $(this).serializeArray();
	data.push({name: 'fromPage', value: window.PROSKTR.content});
	$.ajax({
		url: '/ajax_shopping_cart.php?action=update_product_new',
		type: 'post',
		dataType: 'json',
		data: data,
		success: function( answer ) {
			shoppingCart.setQuantityNPrice( answer );
		}
	});
});
$(document).on('change', '.j-switch-action', function(e) {
	$.ajax({
		url: $(this).attr('action'),
		type: $(this).attr('method'),
		data: $(this).serializeArray(),
		dataType: 'json',
		success: function(msg) {
			location.reload();
		}
	});
});
function initCreateAccountForm() {
	$('form#createAccountForm').on('click', '.j-fast-order-btn', function () {
		$('form#createAccountForm input[name=1click_order_submit]').val('on');
		$('form#createAccountForm').ajaxFormUnbind();
		$('form#createAccountForm').submit();
	});
	$('form#createAccountForm').on('change', '[name=1click_order_submit]', function () {
		var btn = $(this).closest('form').find('.sc-submit');
		var altValue = btn.data('altValue');
		btn.data('altValue', btn.text());
		btn.text(altValue);

		if (this.checked) {
			$('form#createAccountForm').ajaxFormUnbind();
		} else {
			$('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
		}
	});

	$('#createAccountForm input[name=passCode]').on('keyup', function () {
		var val = $(this).val();
		if (val.length == 4) {
			customer.loginByPassCode({
				customerInfo: {
					phone: $('#createAccountForm input[name=phone]').val(),
					passCode: $('#createAccountForm input[name=passCode]').val(),
					clearCart: 1,
				},
				error: function (msg) {
					$('#createAccountForm input[name=phone]').data('validPhoneStatus', 0);
					$('#createAccountForm input[name=passCode]')
						.closest('.j-value')
						.find('.validation-description')
						.html('<div class="error">' + msg.err_mes + '</div>')
						.addClass('not-empty');
				},
				success: function (msg) {
					$('#createAccountForm input[name=phone]').data('validPhoneStatus', 3);
					if (PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after) {
						window.location.reload();
					}
				}
			});
		}
	});

	$('#createAccountForm input[name=passCode]').on('change', function () {
		var val = $(this).val();
		if (val.length > 0 && val.length != 4) {
			$('#createAccountForm input[name=passCode]')
				.closest('.j-value')
				.find('.validation-description')
				.html('<div class="error">� ���������� ���� ������ ���� 4 �����</div>')
				.addClass('not-empty');
		}
	});

	$('#createAccountForm .js-sendNewPassCode').on('click', function (e) {
		e.preventDefault();
		var $form = $('#createAccountForm');
		if ($('input[name=phone]', $form).val() != '') {
			$.ajax({
				url: '/ajax_customer.php?action=resendNamePassCode&ajax=1',
				type: 'POST',
				data: $form.serializeArray(),
				dataType: 'json',
				context: this,
				success: function (msg) {
					$('.hint', $form).html(msg.err_mes);
				}
			});
		}
	});

	$('#createAccountForm .js-reget-pass-call').on('click', function (e) {
		e.preventDefault();
		var $form = $('#createAccountForm'),
			phone = $form.find('input[name=phone]').val();

		if ($('input[name=phone]', $form).val() != '') {
			$.ajax({
				url: '/ajax_customer.php?action=recallWithPassCode&ajax=1',
				type: 'POST',
				data: {
					login: phone,
				},
				dataType: 'json',
				context: this,
				success: function (msg) {
					$('.hint', $form).html(msg.err_mes);
				}
			});
		}
	});
}

$('#notEmptyCart').on('change', 'input[name=useMindboxBonuses]', function() {
	updateCashback();
});

function CreateAccountMindboxFormSubmit( id ) {
	let hasEmpty = false;
	let $form = $('#' + id);
	let $btn = $('.btn-submit', $form);

	if ( $btn.hasClass('disabled') ) return;
	$btn.addClass('disabled');

	$('input[type=text]', $form).each(function(){
		if ( $(this).val() == '' ) {
			$(this).addClass('error');
			hasEmpty = true;
		}
	});
	if ( hasEmpty ) {
		$btn.removeClass('disabled');
		return;
	}
	$.ajax({
		url: '/ajax_mindbox.php',
		type: 'POST',
		data: $form.serializeArray(),
		dataType: 'json',
		context: this,
		success: function( msg ) {
			$('#createAccountFormMindboxHolder').html(msg.html);
			if ( msg.login ) {
				if ( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
				$('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
				$('form#createAccountForm').submit();
			}
		}
	});
}
