$(function(){
	initDolyami();
	initChatHelp();
	initIndexPage();
	setExtraTopTimer();

	function initChatHelp(){
		var initChatChatra = false;
		$('#chat-help-switcher').change(function(){
			if(initChatChatra){
				return true;
			}
			initChat();
		});

		$('.js-open-help-chat').click(function(){
			if(!$('#chat-help-switcher').prop('checked')){
				$('#chat-help-switcher').prop('checked',true).trigger('change');
			}
			$('[for="open-chat-help-switcher"]').trigger('click');
		});
		function initChat() {
			window.ChatraGroupID = 'My6w96f3Q6ggQu2qa';
			(function (d, w, c) {
				w.ChatraID = 'gi9TfC2eNNz6WEDNq';
				var s = d.createElement('script');
				w[c] = w[c] || function () {
					(w[c].q = w[c].q || []).push(arguments);
				};
				s.async = true;
				s.src = 'https://call.chatra.io/chatra.js';
				if (d.head) d.head.appendChild(s);
			})(document, window, 'Chatra');

			window.ChatraSetup = {
				mode: 'frame',
				injectTo: 'chatra-wrapper' /* id �����, � ������� ����� ������� ��� */
			};

			$('.js-send-question').click(function () {
				Chatra('sendAutoMessage', $(this).text());
			});
		}
	}

	function initDolyami(){
		$('.js-dolyami').click(function (){
			if((+$(this).attr('data-discount')) === 10 ) {
				$('.js-show-discount').fadeIn(0);
			}else{
				$('.js-show-discount').fadeOut(0);
			}
		});
	}

	function initIndexPage(){
		initTopSlider();

		function initTopSlider(){
			$('.js-index-banner').slick({
				responsive: [
					{
						breakpoint: 991,
						settings: {
							dots: true
						}
					},
				]
			});
		}
	}

	/**
	 * ������ ��������� �
	 */
	function setExtraTopTimer() {
		if ( $('.js-top_popup-timer').length == 0 ) return;
		let $holderElement = $('.js-top_popup-timer');
		let endDateStr = $holderElement.data('end_time');

		let timer = setInterval(function () {
			let now = new Date();
			let date = new Date(endDateStr);
			let msLeft = date - now;
			if (msLeft <= 0) {
				clearInterval(timer);
				$holderElement.hide();
			} else {
				let res = new Date(msLeft);
				let strTimer = '';
				if ( res.getUTCDate() > 1 ) {
					strTimer = res.getUTCDate() - 1 + ' ' + getDays(res.getUTCDate() - 1) + ' ';
				}
				strTimer = strTimer + padNumber(res.getUTCHours(), 2) + ':' + padNumber(res.getUTCMinutes(), 2) + ':' + padNumber(res.getUTCSeconds(), 2);
				$holderElement.html( strTimer );
				$holderElement.show();
			}
		}, 1000);

		function padNumber(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}

		function getDays( cnt ) {
			if ( cnt == 1 ) {
				return '����';
			} else if ( cnt < 5 ) {
				return '���';
			} else {
				return '����';
			}
		}
	}
});