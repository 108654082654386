$(document).on('change', '.rating input[name=rating]', function(){
	var rating = $('.rating input[name=rating]:checked').val();
	$('.rating .rating-star').removeClass('active');
	$('.rating .rating-star').each(function(index){
		if ( index < rating ) {
			$(this).addClass('active');
		}
	});
});
$(document).on('change', '#show_only_name', function() {
	$(this).closest('.main-button').toggleClass('not-active');
	$('#review_customer_name, #review_manual_name').toggle();
	$('#review_manual_name:visible input').focus();
});

$(document).on('click', '.publication_rules', function( e ) {
	e.preventDefault();
	$('.publication_rules_div').toggle();
});
$(document).on('click', '#FirstPartFormSubmit', function( e ) {
	e.preventDefault();
	$('#FirstPartForm').submit();
} );
$(document).on('submit', '#FirstPartForm', function( e ) {
	$(this).find('.error').empty();
	if( $(this).find('input[name=rating]:checked').length == 0 ) {
		$('#rating-error').text('�� �� ������� �������.');
		e.preventDefault();
	}
	if( $(this).find('[name=review]').val().length < 15 ) {
		$('#review-error').text("���� '����� ������' ������ ��������� �� ����� 15 ��������.");
		e.preventDefault();
	}
	if ( $(this).find('[name=email_address]').val().length > 0 && !/[-0-9a-z_]+@[-0-9a-z_^.]+\.[a-z]+/i.test( $(this).find('[name=email_address]').val() ) ) {
		$('#email-error').text("�� ���������� ������ E-mail");
		e.preventDefault();
	}
});
$(document).on('click', '#a_bonus_info', function(){
	$.magnificPopup.open({
		items: {
			src: '#bonus_info',
			type: 'inline',
		},

	});
});

$('.show-more').click(function( e ) {
	e.preventDefault();
	var form = $('#reviews-list-form');
	if( parseInt(form.attr('loading')) == 1 ) {
		return false;
	}
	form.attr('loading', 1);
	$(this).addClass('loading');
	var page_el = form.find('input[name=page]');
	page_el.val( parseInt(page_el.val()) + 1 );
	formChangeFunc( form, true, true );
	// if ( parseInt( $(this).data('pages') ) >= parseInt( page_el.val() ) ) {
	// 	$(this).hide();
	// }
});

$('#reviews-list-form').change( function( e ) {
	var form = $(this);
	if( parseInt(form.attr('loading')) == 1 ) {
		return false;
	}
	form.attr('loading', 1);
	form.find('input[name=page]').val(1);
	formChangeFunc( form, false, true );
} );

var formChangeFunc = function( form, appendFlag, historyFlag ) {
	var formData = form.serializeArray();
	if(historyFlag && history.pushState) {
		var queryString = $.param(formData);
		history.pushState(formData, $("title").text(), form.attr('action')+'?'+queryString.replace('&ajax=3',''));
	}
	$.ajax({
		url: form.attr('action'),
		data: formData,
		dataType: 'json',
		success: function( msg ) {
			if( appendFlag ) {
				$('.reviews-list').append( msg.data.content );
			}else {
				$('.reviews-list').html( msg.data.content );
				$(window).scrollTop(0);
			}
			form.attr('loading', 0);
			$('.reviews-block .count-model').text(msg.pagination.itemCountText);
			$('.pagination-page-list.pagination-link').html(msg.pagination.html);
			$('.show-more').removeClass('loading');
			if( parseInt( msg.pagination.pageNumber ) >= parseInt( msg.pagination.pageCount ) ) {
				$('.show-more').hide();
			}
			initRate();
		},
	});
}
$(document).on('click', '.yandex_tbl .check_review', function(e) {
	e.preventDefault();
	$.ajax({
		type: "GET",
		url: "reviews_success.php?action=check_review",
		// dataType : "json",
		data: {
			orders_id: $('#current_orders_id').val(),
			orders_id_b: $('#current_orders_id_b').val(),
			website: $(this).data('website'),
		},
		context: this,
		success: function(){
			$(this).html('��� ����� ����� �������� � ������� 3-5 ����.');
			$(this).addClass('just_send_review');
			$(this).removeClass('check_review');
		},
	});
});